
.list .date {
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 3.5rem;
    margin: 0;
}

.list .date:hover {
    background-color: #ebebeb;
}

.list .date:focus {
    background-color: #fbe7f3;
}

.event-cal a {
    color: #000000 !important;
}

.list {
    width: 100%;
    margin-bottom: 1rem;
}

.list, .list ul {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.list li {
    list-style: none;
}

.list li button {
    background-color: transparent;
}

.list .date:active {
    background-color: #fbe7f3;
}

.list .date {
    width: 100%;
    padding: 1rem 1.3rem;
    background-color: transparent !important;
}

.list .date:active, .list .date.chosen {
    background-color: rgb(251, 226, 253) !important;
}

.list .date:hover {
    background-color: rgb(236, 236, 236) !important;
}

.list .date .icon {
    margin-top: -0.6rem !important;
    transition: .25s ease;
}

.list .date.child {
    margin-left: 1.5rem;
    color: rgb(109, 109, 109) !important;
}

.add, .reset {
    width: 100%;
    display: block;
    background-color: #d60c8c !important;
    color: white !important;
    padding: 1rem !important;
}

.reset {
    padding: 1rem !important;
    margin-top: 3px !important;
    background-color: rgb(71, 71, 71) !important;
}

.list .icon {
    position: absolute;
    margin-left: -2%;
}

.event-cal .name-event {
    font-size: 1.5rem;
}

.event-cal .divider-dott {
    text-align: center;
    padding: 0.5rem;
}

.ui.rating .active.icon,
.icon.clock.outline,
.pinkStar {
    color: #d60c8c;
    font-weight: bold;
}

.info-bar {
    justify-content: flex-start !important;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.event-cal ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.price-calc {
    position: absolute;
    margin-top: -2.5rem;
    margin-left: 25rem;
}

.price-calc .discount {
    border-radius: 50%;
    background-color: rgb(214, 12, 140);
    font-size: 0.8rem;
    color: rgb(255, 255, 255);
    height: 2.7rem !important;
    width: 2.7rem !important;
    position: absolute;
    top: -4rem;
    left: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-calc .discount strong {
    position: absolute;
}

.price-calc .before {
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: line-through;
}

.price-calc .price {
    font-size: 3rem;
    font-weight: bold;
    color: #d60c8c;
    margin-left: 0.3rem;
}

.inputs .column:nth-child(1) {
    padding-left: 0 !important;
}


@media only screen and (max-width: 1130px) {
    .discount {
        top: -0.3rem !important;
        left: 11.5rem !important;
    }

    .activity-name-header{
        font-size: 30px !important;
    }

    .price-calc {
        margin: 0;
        margin-top: 2rem;
        position: relative;
    }
}

@media only screen and (max-width: 365px) {
    .name-event {
        font-size: 16px !important;
    }
    
    .activity-name-header{
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 340px) {
    .info-bar > .divider-dott {
        padding: 0 !important;
    }

    .info-bar {
        justify-content: space-around !important;
    }
}

@media only screen and (max-width: 480px) {
    .discount {
        margin-left: 7rem;
    }
}

/*  REVIEWS */

.review {
    text-align: center;
}

.name {
    font-size: 1rem;
    font-weight: normal;
}

.invisible {
    color: white;
}

.text {
    margin-top: 0.5rem;

}

.flex .inline {
    display: inline-block;
    vertical-align: top;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.flex .inline2 {
    display: inline-block;
    vertical-align: top;
    margin-right: 2rem;
    margin-bottom: 2rem;
}

.flex {
    display: flex;
    flex-direction: row;

}

.hodnotenie {
    margin-left: 4rem;
}

.mrgn {
    margin-left: 4rem;
    /*margin-right: 2rem;*/
}

.flex .inline2 .content {
    text-align: justify;
    padding-right: 3rem;

}

.ui.star.rating .active.icon:before {
    color: #d60c8c;
    text-shadow: none;
}

.mrg {
    margin-bottom: 2rem;
}


/* SIMILAR ACTIVITIES */

.activities .learnMoreButton,
.activities .icnPink {
    color: #d60c8c;
}

.icnPink {
    color: #d60c8c;
}

.slick-prev:before,
.slick-next:before {
    color: #d60c8c !important;
}

.activities .btn {
    padding-top: 1rem;
}

.activities .icnBlack {
    color: black;
}

.activities .icon {
    padding-bottom: 3rem;
}

.activities {
    padding: 3rem;
}

.activities .actHeader {
    margin-left: 4rem;
}

.activities .three-cards {
    margin-left: 3rem;
}

.ui.card.cardHover:hover {
    background-color: #cfcfcf;
}

.ui.grid > .row > .column {
    padding-right: 0;
}

.ui.star.rating .active.icon:before {
    color: #d60c8c;
    text-shadow: none;
}



