.footer {
    background-color: #232021;
    color: #fefefe;
    /* width: 100%; */
    font-size: 20px;
    margin-top: 3rem !important;
}

.footer .logoHead {
    padding-bottom: 4rem;
    padding-top: 2rem;
    padding-left: 5rem;
}

.footer a {
    text-decoration: none;
    color: #fefefe;
}

.footer a:hover {
    text-decoration: none;
    color: #adadad !important;
}

.footer h1 {
    font-size: 26;
    font-weight: bold;
}

.footer .socials {
    padding-bottom: 1rem;
}

.footer .icon {
    margin: 1rem;
}

.footer > .linking {
    margin-left: 5rem;
}

.linkBlock {
    padding-bottom: 2rem;
}

@media (max-width: 992px) {
    .footer .logoHead {
        padding-left: 0;
    }

    .linking.row {
        max-width: calc(100% - 1rem);
        margin-left: 1rem;
    }

    .footer.ui.grid > .row {
        margin-left: 1rem !important;
    }
}

@media (max-width: 768px) {
    .footer {
        text-align: center !important;
    }

    .footer img {
        margin-top: 1rem;
    }
}