.activities .learnMoreButton {
    /*     color: white;
     */
    color: #d60c8c;
    transition: .25s ease-in;
    padding: 1rem 0;
    font-size: 1.3rem;
}

.activities .learnMoreButton:hover {
    color: #e642a9;
}


.activities .icnPink {
    color: #d60c8c;
}

.icnPink {
    color: #d60c8c;
}

.slick-prev:before,
.slick-next:before {
    color: #d60c8c !important;
}

.activities .card {
    width: 100%;
}

.activities .btn {
    padding-top: 1rem;
}

.activities .icnBlack {
    color: black;
}

.activities .icon {
    padding-bottom: 3rem;
}

.activities {
    padding: 3rem;
}

.activities .actHeader {
    margin: 0 0 1rem 4rem;
}


.activities .three-cards {
    margin-left: 3rem;
}

.ui.card.cardHover:hover {
    background-color: #cfcfcf;
}

.ui.grid > .row > .column {
    padding-right: 0;
}

.ui.star.rating .active.icon:before {
    color: #d60c8c;
    text-shadow: none;
}

@media (max-width: 992px) {
    .ui.grid > .row {
        margin-left: 0 !important;
    }

    .ui.grid > .row > .column {
        padding-left: 0 !important;
    }
}


@media (max-width: 530px) {
    .activities .actHeader {
        margin: 0 0 1rem 2rem !important;
    }
}




