.registerimage {
    color: #232021;
}

.registerimage .map {
    max-width: 100%;
    height: auto;
    display: block;

}

.registerimage .register {
    margin-top: 3rem;
    text-align: center;
}

.registerimage .register h2 {
    font-size: 2.3rem !important;
}

.registerimage .client {
    text-align: center;
    max-width: 93%;
    margin: 3rem auto;
}

.registerimage .client > h1 {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
}


.registerimage .button {
    margin-top: 1.5rem;
}