/* This file contain AP THEME v1.0 */

/* 
====================================
   Theme Variables
====================================
*/
:root {
    --primary: #d60c8c; /*#D60C92;*/
    --primary-hover: #d60c92;
    --warning: rgb(250, 225, 3);
    --success: rgb(2, 250, 2);
    --light: #f6f6f6;
    --white: #ffffff;
    --dark: #363636;
    --black: #000000;
    --light-grey: #505050;
    --body-bg: #f5f5f5;
    --dashboard-nav: #3b4654;
    --dashboard-nav-hover: #2c313d;
    --variant1: #a1a1a1;
}

/* 
====================================
   Theme Fonts
====================================
*/
@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/fonts/AvenirNextLTPro-Regular.woff");
}

/* 
====================================
    Theme Default Styles
====================================
*/
body {
    margin: 0;
    box-sizing: border-box;
    font-family: "AvenirNextLTPro";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--body-bg);
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
p {
    line-height: 1.5 !important;
}

/* 
====================================
    Bouncing Loader animation
====================================
*/
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10%;
}

/* Loader circles */
.loader > span {
    background: var(--primary);
    border-radius: 50%;
    margin: 5rem 0.5rem;
    animation: bouncingLoader 0.6s infinite alternate;
}

.loader > span:nth-child(2) {
    animation-delay: 0.2s;
}

.loader > span:nth-child(3) {
    animation-delay: 0.4s;
}

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
    from {
        width: 0.5rem;
        height: 0.5rem;
        opacity: 1;
        transform: translate3d(0);
    }
    to {
        width: 2rem;
        height: 2rem;
        opacity: 0.1;
        transform: translate3d(0, -2rem, 0);
    }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px !important;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 16px !important;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px !important;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 16px !important;
    }
}

.search-box > div{
    border-radius: 0 !important;
}

.special-event{
    border-bottom: 2px solid orange !important;
    border-color: orange !important;
}

/* 
====================================
SemanticUI Overides Variables
====================================
*/
.ui.search .prompt {
    border-radius: 0;
    width: 500px;
}

.ui.images .image,
.ui.images > img,
.ui.images > svg {
    margin: 0 !important;
}

/* Cards */
.card.event-type-active,
.card.event-type-active:hover {
    background-color: var(--primary);
}

.card.registration-account-type {
    border: 3px solid var(--light);
}

.card.registration-account-type:hover {
    border: 3px solid var(--primary);
}

.button {
    border-radius: 0 !important;
}

.ap-slider .slick-dots {
    bottom: +10px !important;
}

.ap-slider .slick-dots li button::before {
    font-size: 10px;
}

.ap-slider .slick-dots li:hover button::before {
    color: var(--primary) !important;
}

.ap-slider .slick-dots li.slick-active button::before {
    color: var(--primary) !important;
}

/* 
====================================
    Error Page Styles
====================================
*/
.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    height: 100vh;
    width: 100%;
    text-align: center;
    color: var(--light);
}

.error-page code {
    font-size: 10rem;
    text-align: center;
}

.error-page p {
    margin: 0;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
}

.error-page a {
    margin: 0;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    text-align: center;
}

div.center {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

div.error body {
    font-family: "AvenirNextLTPro";
}

/* 
====================================
    Custom Styles
====================================
*/
.custom-btn {
    height: 63px;
    padding: 0px 10px;
    margin-bottom: 40px;
    border: 2px solid var(--primary);
    background-color: var(--primary);
    color: var(--light);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.8;
}

.custom-btn:hover {
    opacity: 1;
}

div.center-segment {
    height: auto;
}

/* Tutorial Steps */
.tutorial-step {
    font-family: "AvenirNextLTPro";
    padding: 0.2rem 0.2rem 0.2rem 2rem;
    border-left: 6px solid var(--light);
    text-align: left;
    font-size: 15px;
    color: var(--light);
    opacity: 0.6;
}

.tutorial-active {
    font-weight: bold;
    opacity: 1;
}

.tutorial-passed {
    border-left: 6px solid var(--primary);
    font-weight: bold;
    color: var(--primary);
    opacity: 1;
}

.social-network-order {
    display: flex;
}

.full-size {
    padding: 0 !important;
}

.gallery-image {
    padding: 0.5rem !important;
}

.big-h1 {
    font-weight: 600;
    font-size: 2.1rem;
    padding: 0.5rem 0 1rem;
    line-height: 3rem;
    text-transform: uppercase;
}

/* 
=======================================
    Google Map Styles - Search Service
=======================================
*/
.pac-logo::after {
    display: none !important; /* Powered by Google logo for autocomlete items was hidden */
}

.pac-item {
    cursor: pointer;
    padding: 10px;
}

.search-bar-block {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    white-space: nowrap;
    background: var(--light);
}

.info-block-wrapper {
    position: fixed;
    width: auto;
    display: block;
    bottom: 1rem;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    white-space: nowrap;
    max-height: 86vh;
    height: auto;
    padding: 0.5rem;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #d60c8c #f1f1f1;
}

.info-block-wrapper::-webkit-scrollbar {
    width: 3px;
}
.info-block-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.info-block-wrapper::-webkit-scrollbar-thumb {
    background: #d60c8c;
    border-radius: 0;
}

.info-block-wrapper .card {
    margin-bottom: 0.5rem !important;
}

.search {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
}

#map-search-bar {
    width: 400px;
    height: 40px;
    margin-top: 10px;
    padding-left: 5px;
    border: 1px solid var(--light-grey);
    border-radius: 0;
}

#map-search-bar {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid var(--light-grey);
}

#map-search-bar:focus,
#map-search-bar:active {
    outline: none;
    border-bottom: 2px solid var(--primary);
}

.location-button {
    position: relative;
    left: -50px;
    padding: 13px !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    color: var(--dark) !important;
}

.location {
    position: relative;
    left: -40px;
}

.non-activities {
    position: absolute;
    bottom: 50px;
    margin-left: 7px;
}

.with-activities {
    position: absolute;
    bottom: 220px;
    margin-left: 7px;
}

.info-window {
    padding: 20px;
    width: 200px;
    background: var(--light);
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    text-align: center;
    margin-left: -100px;
    margin-top: -230px;
}

.gm-ui-hover-effect {
    top: -230px !important;
    right: 4px !important;
    font-size: 10px;
}

.gm-ui-hover-effect > img {
    width: 20px !important;
    height: 20px !important;
}

.highlight-card {
    top: -10px;
    border-bottom: 4px solid var(--primary) !important;
}

/*
====================================
    Styles for slider
====================================
*/

.slider {
    position: relative;
    height: 100%;
    max-height: 100vh;
}

.slider .carousel,
.slider .carousel-root {
    height: 100% !important;
    max-height: 100vh !important;
}

.slider .back,
.banner-wrapper .back {
    bottom: 30%;
    position: absolute;
    background: white;
    left: 0;
    padding: 2.3rem 4rem;
    max-width: 35rem;
}

.slider .text,
.banner-wrapper .text {
    font-weight: 700;
    font-size: 1.8rem;
    padding: 0.5rem 0 1rem;
    line-height: 2rem;
}

.slider .back .btnSearch,
.banner-wrapper .back .btnSearch {
    font-weight: bold;
    padding: 1.5rem 3.3rem;
    font-size: 1.2rem;
    color: white;
    background-color: #d60c8c;
    border-radius: 0;
    transition: 0.2s ease;
}

.slider .back .btnSearch:hover,
.banner-wrapper .back .btnSearch:hover {
    color: white;
    background-color: #e22c9f !important;
}

/*
====================================
    Styles for sections
====================================
*/

.section {
    padding: 3rem 0 3rem !important;
}

.section-detail {
    padding: 1rem 0 3rem !important;
}

/*
====================================
    Styles for banner
====================================
*/
.banner-container {
    height: 52vh;
    background-position: center center;
}

.banner-wrapper {
    height: 100%;
    position: relative;
}

/*
====================================
    Styles for map details
====================================
*/

.map .details {
    padding: 2rem 0 0;
}

.map .details .detail,
.map .details .navigate {
    width: 100%;
    display: block;
    padding: 1rem 2rem;
    color: black;
    background-color: #ebebeb;
    border-radius: 3px;
    min-height: 3.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
}

.map .details .navigate {
    cursor: pointer;
    background-color: #d4e9b6;
}

.map .details .detail {
    justify-content: flex-start;
}

.map .details .detail span,
.map .details .navigate span {
    font-size: 1.2rem;
}

.map .details .navigate span {
    font-weight: bold;
    text-transform: uppercase;
}

.map .details .detail span:after {
    content: "•";
    font-size: 1rem;
    display: inline;
    margin: 10px;
}

.map .details .detail span:last-child:after {
    content: "" !important;
}

.map .details .detail .walk .icon {
    padding: 0 !important;
    margin: 0;
    margin-right: 10px;
}

/*
====================================
    Styles for Info Box (e.g. contact page)
====================================
*/

.box h2 {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.box .toggle {
    color: #d60d8c;
    font-weight: bold;
    font-size: 1.1rem;
    transition: 0.23s ease-in;
}

.box a {
    color: #d60d8c;
}

.customLinks a {
    color: #4183c4 !important;
}

.box a:hover {
    color: #eb4ab0;
}

.box {
    background-color: #ebebeb;
    padding: 2rem;
    border-radius: 3px;
}

.box > div:not(:nth-child(1)) {
    margin-top: 2rem;
}

.box ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

.box ul li {
    padding-left: 0;
    margin-left: 0;
}

.box .column {
    padding-left: 0 !important;
}

.box ul li {
    line-height: 1.5rem;
}

.box ul li.space:after {
    content: " ";
}

.box .icon {
    margin-right: 1.5rem;
    cursor: pointer;
}

.box .toggle .icon {
    margin-right: 0.3rem;
}

/*
====================================
    Styles for Responsive Design
====================================
*/
@media (min-width: 320px) {
    table {
        display: block;
    }

    .ui .table {
        border: none !important;
    }
}

@media (max-width: 700px) {
    #map-search-bar {
        width: 70vw;
    }
}

@media (min-width: 768px) {
    table {
        display: table;
    }

    .ui .table {
        border: 1px solid rgba(34, 36, 38, 0.15) !important;
    }

    .social-network-order {
        display: block;
    }
}

@media (max-width: 768px) {
    .map .details .detail span,
    .map .details .navigate span {
        font-size: 1rem;
    }

    .info-block-wrapper {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        height: auto;
        align-items: baseline;
    }

    .info-block-wrapper .card {
        display: inline-block !important;
        margin-right: 1rem !important;
    }
}

@media (max-width: 1050px) {
    .slider .back {
        bottom: 10%;
        padding: 1.5rem 2.7rem;
    }
}

@media (max-width: 800px) {
    .slider .carousel,
    .slider .carousel-root {
        height: 80vh !important;
        max-height: 100vh !important;
    }
}

@media (max-width: 490px) {
    .slider .back {
        width: 100%;
        bottom: 10%;
        padding: 1rem 2.3rem;
    }

    .slider .back .text {
        font-size: 1em;
    }

    .slider .back .btnSearch {
        padding: 0.8rem 2rem;
    }
}

@media (max-width: 991px) {
    .info-block-wrapper {
        max-height: 72%;
    }

    .button-pay-fluid {
        width: 100%;
    }
}

@media (min-width: 991px) {
    .button-pay-order {
        float: right;
    }
}

/*
====================================
    Cookie Consent Styles
====================================
*/

/*
====================================
    Utilities
====================================
*/

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.text-center {
    text-align: center !important;
}

.pinkStar {
    color: #d60c8c;
    font-weight: bold;
}

.flex-row {
    display: flex;
    align-items: "center";
    flex-direction: row;
    width: "100%";
}
