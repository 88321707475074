
.navbar a {
    text-decoration: none;
    color: #000000 !important;
}

.navbar a:hover {
    text-decoration: none;
    color: #adadad !important;
}

.navbar .icons {
    text-align: right !important;
}

.navbar .back i {
    height: auto;
    margin-bottom: 0;
    margin-top: 0px;
}

.navbar .icon {
    margin: 0.7rem;
}

.navbar .ui.search .prompt {
    width: 20.24rem;
    color: #000000 !important;
}

.navbar .ui.input > input {
    text-align: center;
    padding: 1rem;
    background: #ebebeb;
    border: 0;
    border-radius: 0;
}

.navbar .ui.input.focus > input,
.navbar .ui.input > input:focus {
    background: #c9c9c9;
}

.navbar .ui.icon.input > i.icon {
    opacity: 1;
}

.navbar .btnSearch {
    font-weight: bold;
    padding: 0.8rem 3rem;
    font-size: 1.2rem;
    color: #929292 !important;
    background-color: #e0dfdf;
    border-radius: 0;
    transition: .2s ease;
    text-transform: uppercase;
    white-space: nowrap;
    word-break: keep-all;
}

.navbar .btnSearch:hover {
    color: white !important;
    background-color: #C4C4C4 !important;
}

.navbar .dropdown.icon {
    position: relative;
    top: 0.23rem;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-right: 0.2rem;
}

.navbar .filtering-container {
    width: 80% !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2 1 auto;
}

.navbar .filtering-container #map-search-bar {
    font-weight: bold;
    padding: 1.5rem 2rem;
    font-size: 16px !important;
    color: #929292 !important;
    margin-top: 4px;
    background-color: #e0dfdf;
    border-radius: 0;
    transition: .2s ease;
    border: 0;
    text-align: center;
    margin-right: 5px;
    max-width: 60%;
}

.navbar .filtering-container .location-button {
    position: initial;
    left: 0;
    width: auto;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    background-color: #222 !important;
    color: white !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar .filtering-container .location-search {
    position: initial;
    left: 0;
    width: auto;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    color: white !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e03997;
}

.navbar .map-menu-dropdown{
    min-width: 200px !important;
    left: auto !important;
    right: 13% !important;
}

.map-menu-dropdown a:hover{
    color: black !important;
}

@media (max-width: 1140px) {
    .btnSearch {
        font-size: 0.8rem !important;
    }
}


@media (max-width: 994px) {
    .navbar .icon {
        margin: 0.3rem;
    }

    .navbar .filtering-container #map-search-bar {
        max-width: 70%;
    }

    .navbar .map-menu-dropdown{
        right: -10% !important;
    }
}

@media (max-width: 991px) {
    .navbar .btn {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .navbar h4 {
        text-align: left !important;
    }

    .navbar .back {
        text-align: right !important;
    }

    .navbar .force-md-center {
        text-align: center !important;
    }

    .navbar .map-menu-dropdown{
        right: -10% !important;
    }
}

@media (max-width: 768px) {
    .navbar .img {
        text-align: center !important;
    }

    .navbar .btn .btnSearch {
        /* display: flex; */
        width: 90%;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        font-size: 1.2rem !important;
    }

    .mobile-nav-block{
        display: flex;
        width: 100%;
    }

    .navbar .back {
        text-align: center !important;
    }

    .navbar h4, .navbar .icons {
        text-align: center !important;
    }

    .navbar .map-menu-dropdown{
        right: -5% !important;
    }
}

@media (max-width: 380px) {
    .navbar .btn .btnSearch {
        width: 100%;
    }

    .mobile-nav-block{
        display: flex;
        width: 100%;
    }

    .navbar .map-menu-dropdown{
        width: 100% !important;
        right: 0 !important;
    }
}


/* @media (max-width: 992px){
  .ui.grid>[class*="right aligned"].column.column {
   
    text-align: center;
    
  }
  .ui.search{
    display: flex;
    justify-content: center;
 
  }
 
} */







 